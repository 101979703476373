export const getNewDate = () => {
  // 获取当前时间
  const today = new Date();
  // 获取当前时间(today)的年份
  const year = today.getFullYear();
  // 获取月份
  const month = String(today.getMonth() + 1).padStart(2, "0");
  // 获取当前日
  const day = String(today.getDate()).padStart(2, "0");
  // 得到年月日
  return `${year}/${month}/${day}`;
};

//比较两个日期大小
export const compareDates = (date1, date2) => {
  var timestamp1 = new Date(date1).getTime();
  var timestamp2 = new Date(date2).getTime();
  if (timestamp1 < timestamp2) {
    console.log(date1 + " 在 " + date2 + " 之前");
    return true;
  } else if (timestamp1 > timestamp2) {
    console.log(date1 + " 在 " + date2 + " 之后");
    return false;
  } else {
    console.log(date1 + " 与 " + date2 + " 相同");
    return false;
  }
};

//计算日期相差天数
export const countDates = (date1, date2) => {
  // 将字符串转换为Date对象
  var d1 = new Date(date1);
  var d2 = new Date(date2);

  // 获取时间戳（单位：毫秒）
  var timeDiff = Math.abs(d2.getTime() - d1.getTime());

  // 计算天数差
  var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return diffDays + 1;
};

//生成字符串
export const datesString = (date1, date2, day) => {
  // 将字符串转换为Date对象
  date1 = date1.split("-");
  date2 = date2.split("-");
  let name1 = date1[0] + "年" + date1[1] + "月" + date1[2] + "日";
  let name2 = date2[0] + "年" + date2[1] + "月" + date2[2] + "日";
  return `${name1}-${name2},共${day}天`;
};
