<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="text-center" rowspan="2" colspan="2">
            <img class="tbale-img1" src="@/assets/image/table/tbale-img1.jpg" />
          </td>
          <td class="text-center" rowspan="2" colspan="6">
            <h1>
              Employee Medical Insurance Adjustmentment Application<br />
              中东公司员工医疗保险等级变更及家属医疗保险注册审批表
            </h1>
          </td>
          <td class="text-right">Application Date<br />申请日期</td>
          <td class="text-center">
            {{ ApplicationDate | tableDateFrom }}
          </td>
        </tr>
        <tr>
          <td class="text-right">Workflow Table S/N<br />表单编号</td>
          <td class="text-center">
            <p>{{ ApplicationNumber }}</p>
          </td>
        </tr>

        <tr>
          <td class="text-center underline-text">
            Employee ID <br />
            员工编码
          </td>
          <td class="text-center">
            <el-input
              v-model="EmployeeCode"
              @blur="changeEmployeeID"
              placeholder="请输入 Enter"
            />
          </td>
          <td class="text-center">
            Employee Name <br />
            员工姓名
          </td>
          <td class="text-center" colspan="2">
            <el-input v-model="NameinChinese" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            Gender<br />
            性别
          </td>
          <td class="text-center">
            <el-select
              v-model="Gender"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in opinions3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center" colspan="2">Nationality 国籍</td>
          <td class="text-center">
            <el-select
              v-model="Nationality"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in nationalityList"
                :key="item.ID"
                :label="item.PositionsCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Project Name<br />
            项目名称
          </td>
          <td class="text-center">
            <el-select
              v-model="ProjectID"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
              @change="changeProjectID"
            >
              <el-option
                v-for="item in projectList"
                :key="item.ProjectID"
                :label="item.ProjectName"
                :value="item.ProjectID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            Sub Project Name<br />
            分项目名称
          </td>
          <td class="text-center">
            <el-select
              v-model="SubProjectID"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in subPojectList"
                :key="item.ProjectID"
                :label="item.ProjectName"
                :value="item.ProjectID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            Working Unit<br />
            用工单位
          </td>
          <td class="text-center">
            <el-select
              v-model="CompanyID"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in companyList"
                :key="item.ID"
                :label="item.CompanyCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            Job Profession<br />
            岗位名称
          </td>
          <td class="text-center" colspan="3">
            <el-select
              v-model="JobProfession"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in positionList"
                :key="item.ID"
                :label="item.PositionsCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            Customs Entry Number<br />
            海关入境号
          </td>
          <td class="text-center">
            <el-input v-model="BorderNumber" placeholder="请输入 Enter" />
          </td>
          <td class="text-center" colspan="2">
            Temporary Residence ID<br />
            National ID 暂住证号
          </td>
          <td class="text-center">
            <el-input v-model="IQAMAID" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">SPONSOR</td>
          <td class="text-center">
            <el-select
              v-model="SPONSOR"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in opinions1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            Class Level<br />
            医保等级
          </td>
          <td class="text-center">
            <el-select
              v-model="ClassID"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in classList"
                :key="item.ID"
                :label="item.NameinChinese"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-center" rowspan="2">Adjustment 1</td>
          <td class="text-left" colspan="6">
            Employee Medical Insurance Level Adjustment 员工本人医保等级调整
          </td>
          <td class="text-center" colspan="2">
            New Class Level<br />
            拟调整等级
          </td>
          <td class="text-center">
            <el-select
              v-model="EmployeeNewClassLevel"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in classList"
                :key="item.ID"
                :label="item.NameinChinese"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-left">Reason 原因</td>
          <td class="text-center" colspan="8">
            <el-input
              type="textarea"
              v-model="EmployeeAdjustmentReason"
              :rows="2"
              placeholder="请输入 Enter"
            ></el-input>
          </td>
        </tr>
        <tr>
          <td class="text-center" rowspan="2">Adjustment 2</td>
          <td class="text-left" colspan="6">
            Dependents of Employee Medical Insurance Level Adjustment
            员工家属医保等级调整
          </td>
          <td class="text-center" colspan="2">
            Current Class Level<br />
            原医保等级
          </td>
          <td class="text-center">
            <el-select
              v-model="DependentsCurrentClassLevel"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in classList"
                :key="item.ID"
                :label="item.NameinChinese"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-left">Reason 原因</td>
          <td class="text-center" colspan="8">
            <el-input
              type="textarea"
              v-model="DependentsAdjustmentReason"
              :rows="2"
              placeholder="请输入 Enter"
            ></el-input>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Dependent Name<br />
            员工家属姓名
          </td>

          <td class="text-center">
            Date of Birth<br />
            出生日期
          </td>
          <td class="text-center">
            Customs Entry Number<br />
            海关入境号
          </td>
          <td class="text-center" colspan="2">
            Temporary Residence ID <br />
            NationalID 暂住证号
          </td>
          <td class="text-center" colspan="2">
            Phone No. In Working Country<br />
            工作国家电话
          </td>
          <td class="text-center" colspan="2">
            Dependents Relationship<br />
            家属关系
          </td>

          <td class="text-center">
            Class Name<br />
            医保等级
          </td>
        </tr>
        <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">
            <el-input
              @blur="changeAdd(index)"
              v-model="item.DependentName"
              placeholder="请输入 Enter"
            />
          </td>

          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="item.DateofBirth"
              type="date"
              placeholder="请选择 Please select"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-input v-model="item.BorderNumber" placeholder="请输入 Enter" />
          </td>

          <td class="text-center" colspan="2">
            <el-input v-model="item.IQAMAID" placeholder="请输入 Enter" />
          </td>

          <td class="text-center" colspan="2">
            <el-input
              v-model="item.PhoneNOinWorkingCountry"
              placeholder="请输入 Enter"
            />
          </td>
          <td class="text-center" colspan="2">
            <el-select
              v-model="item.DependentsRelationship"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in opinions4"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>

          <td class="text-center">
            <el-select
              v-model="item.ClassID"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in classList"
                :key="item.ID"
                :label="item.NameinChinese"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
        </tr>

        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by Department<br />
            单位/部门领导意见
          </td>
          <td class="text-left text-size" colspan="7">
            <el-input
              type="textarea"
              v-model="ApprovedbyDepartmentRemark"
              :rows="2"
              placeholder="请输入 Enter"
            ></el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbyDepartment"
                  :src="ApprovedbyDepartment"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbyDepartmentDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by Proejct Authorized<br />
            项目领导意见
          </td>
          <td class="text-left text-size" colspan="7">
            <el-input
              type="textarea"
              v-model="ApprovedbyProjectAuthorizedRemark"
              :rows="2"
              placeholder="请输入 Enter"
            ></el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbyProjectAuthorized"
                  :src="ApprovedbyProjectAuthorized"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbyProjectAuthorizedDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by SNEMC HR Department<br />
            中东公司人力资源部审批
          </td>
          <td class="text-left text-size" colspan="7">
            <el-input
              type="textarea"
              v-model="ApprovedbySNEMCHRDepartmentRemark"
              :rows="2"
              placeholder="请输入 Enter"
            ></el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbySNEMCHRDepartment"
                  :src="ApprovedbySNEMCHRDepartment"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbySNEMCHRDepartmentDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by SNEMC Authorized<br />
            中东公司授权人审批
          </td>
          <td class="text-left text-size" colspan="7">
            <el-input
              type="textarea"
              v-model="ApprovedbySNEMCAuthorizedRemark"
              :rows="2"
              placeholder="请输入 Enter"
            ></el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbySNEMCAuthorized"
                  :src="ApprovedbySNEMCAuthorized"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbySNEMCAuthorizedDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="10">
            The following content is only displayed online and is not in the
            print area 以下内容仅线上流转显示，非打印区域
          </td>
        </tr>
        <tr>
          <td class="text-center" rowspan="2">
            Douments Upload<br />
            材料上传区域
          </td>
          <td class="text-left" colspan="9">
            * The level of medical insurance shall be implemented in accordance
            with the relevant system requirements of the company. In special
            circumstances, if apply beyond the level, please upload a authorized
            clarification of the situation.<br />
            *
            员工医保等级按照公司相关制度要求执行，特殊情况需越级购买请上传情况说明。
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument1">
              <div @click="download(UploadingDocument1)">
                {{ UploadingDocument1 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(1)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment1"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 1<br />
                材料上传1<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument2">
              <div @click="download(UploadingDocument2)">
                {{ UploadingDocument2 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(2)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment2"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 2<br />
                材料上传2<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="1">
            <div class="shenpi shenpi-color" v-if="UploadingDocument3">
              <div @click="download(UploadingDocument3)">
                {{ UploadingDocument3 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(3)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment3"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 3<br />
                材料上传3<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument4">
              <div @click="download(UploadingDocument4)">
                {{ UploadingDocument4 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(4)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment4"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 4<br />
                材料上传4<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument5">
              <div @click="download(UploadingDocument5)">
                {{ UploadingDocument5 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(5)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment5"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 5<br />
                材料上传5<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  getProjectAll,
  getCompanyAll,
  uploadAttachment,
  getProjectsChildren,
  getDepartmentAll,
  allPosition,
} from "@/api/user";
import {
  getGeneralBasic,
  getUsersCode,
  getEmployeeMedicalInsuranceClassLevelsAll,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    nationalityFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CountryNameCHS : null;
    },
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    purchaseOrderFrom(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.AbbreviationName : null;
    },
    purchaseOrderFrom2(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.CRNO : null;
    },
    stringFormater(data) {
      let name1 = process.env.VUE_APP_SERVER + "upload/";
      let index1 = data.indexOf(name1);
      data = data.slice(index1 + name1.length, data.length - 1);
      const index2 = data.indexOf("/");
      const index3 = data.lastIndexOf(".");
      data = data.slice(index2 + 1, index3);
      return data;
    },
    englishLetterFrom(id, data) {
      let name = "";
      if (!id && id !== 0) {
        return;
      }
      name = data[id];
      return name;
    },
  },
  data() {
    return {
      ApplicationDate: null,
      ID: null,
      KeyID: null,
      ApplicationNumber: null,
      EmployeeID: null,
      EmployeeCode: null,
      NameinChinese: null,
      NameinEnglish: null,
      Gender: null,
      Nationality: null,
      ProjectID: null,
      SubProjectID: null,
      CompanyID: null,
      DepartmentID: null,
      JobProfession: null,
      BorderNumber: null,
      IQAMAID: null,
      SPONSOR: null,
      ClassID: null,
      ClassName: null,
      EmployeeAdjustmentReason: null,
      EmployeeNewClassLevel: null,
      EmployeeNewClassLevelName: null,
      DependentsAdjustmentReason: null,
      DependentsCurrentClassLevel: null,
      DependentsCurrentClassLevelName: null,
      UploadingDocument1: null,
      UploadingDocument2: null,
      UploadingDocument3: null,
      UploadingDocument4: null,
      UploadingDocument5: null,
      EditState: null,
      costAllocationItem: {
        ID: null,
        KeyID: null,
        MainKeyID: null,
        DependentName: null,
        DateofBirth: null,
        BorderNumber: null,
        IQAMAID: null,
        PhoneNOinWorkingCountry: null,
        DependentsRelationship: null,
        ClassID: null,
      },
      opinions1: [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ],
      opinions2: [
        {
          value: true,
          label: "Yes",
        },
        {
          value: false,
          label: "No",
        },
      ],
      opinions3: [
        {
          value: 1,
          label: "男",
        },
        {
          value: 2,
          label: "女",
        },
        {
          value: 3,
          label: "未知",
        },
      ],
      opinions4: [
        {
          value: 1,
          label: "父亲 Father",
        },
        {
          value: 2,
          label: "母亲 Mother",
        },
        {
          value: 3,
          label: "妻子 Wife",
        },
        {
          value: 4,
          label: "儿子 Son",
        },
        {
          value: 5,
          label: "女儿 Daughter",
        },
      ],
      projectList: [],
      subPojectList: [],
      companyList: [],
      positionList: [],
      CostAllocationDetails: [],
      positionCategoryList: [],
      purchaseOrdersList: [],
      nationalityList: [],
      Marriages: [],
      departmentList: [],
      classList: [],

      ApprovedbyDepartment: null,
      ApprovedbyDepartmentRemark: null,
      ApprovedbyDepartmentDate: null,
      ApprovedbyProjectAuthorized: null,
      ApprovedbyProjectAuthorizedRemark: null,
      ApprovedbyProjectAuthorizedDate: null,
      ApprovedbySNEMCHRDepartment: null,
      ApprovedbySNEMCHRDepartmentRemark: null,
      ApprovedbySNEMCHRDepartmentDate: null,
      ApprovedbySNEMCAuthorized: null,
      ApprovedbySNEMCAuthorizedRemark: null,
      ApprovedbySNEMCAuthorizedDate: null,
    };
  },
  methods: {
    getNew() {
      this.ApplicationDate = getNewDate();
    },
    addCostAllocation(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ApplicationNumber: this.ApplicationNumber,
        EmployeeID: this.EmployeeID,
        EmployeeCode: this.EmployeeCode,
        NameinChinese: this.NameinChinese,
        NameinEnglish: this.NameinEnglish,
        Gender: this.Gender,
        Nationality: this.Nationality,
        ProjectID: this.ProjectID,
        SubProjectID: this.SubProjectID,
        CompanyID: this.CompanyID,
        DepartmentID: this.DepartmentID,
        JobProfession: this.JobProfession,
        BorderNumber: this.BorderNumber,
        IQAMAID: this.IQAMAID,
        SPONSOR: this.SPONSOR,
        ClassID: this.ClassID,
        ClassName: this.ClassName,
        EmployeeAdjustmentReason: this.EmployeeAdjustmentReason,
        EmployeeNewClassLevel: this.EmployeeNewClassLevel,
        EmployeeNewClassLevelName: this.EmployeeNewClassLevelName,
        DependentsAdjustmentReason: this.DependentsAdjustmentReason,
        DependentsCurrentClassLevel: this.DependentsCurrentClassLevel,
        DependentsCurrentClassLevelName: this.DependentsCurrentClassLevelName,
        UploadingDocument1: this.UploadingDocument1,
        UploadingDocument2: this.UploadingDocument2,
        UploadingDocument3: this.UploadingDocument3,
        UploadingDocument4: this.UploadingDocument4,
        UploadingDocument5: this.UploadingDocument5,
        EditState,
        Details: [],
      };
      this.CostAllocationDetails.forEach((item) => {
        if (item.DependentName && item.ClassID) {
          data.Details.push(JSON.parse(JSON.stringify(item)));
        }
      });
      return data;
    },
    geteditData(params) {
      console.log("params", params);

      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.ApplicationDate = params.CreateDate;
      this.ApplicationNumber = params.ApplicationNumber;
      this.EmployeeID = params.EmployeeID;
      this.EmployeeCode = params.EmployeeCode;
      this.NameinChinese = params.NameinChinese;
      this.NameinEnglish = params.NameinEnglish;
      this.Gender = params.Gender;
      this.Nationality = params.Nationality;
      this.ProjectID = params.ProjectID;
      this.SubProjectID = params.SubProjectID;
      this.CompanyID = params.CompanyID;
      this.DepartmentID = params.DepartmentID;
      this.JobProfession = params.JobProfession;
      this.BorderNumber = params.BorderNumber;
      this.IQAMAID = params.IQAMAID;
      this.SPONSOR = params.SPONSOR;
      this.ClassID = params.ClassID;
      if (this.ClassID) {
        this.costAllocationItem = this.ClassID;
      }
      this.ClassName = params.ClassName;
      this.EmployeeAdjustmentReason = params.EmployeeAdjustmentReason;
      this.EmployeeNewClassLevel = params.EmployeeNewClassLevel;
      this.EmployeeNewClassLevelName = params.EmployeeNewClassLevelName;
      this.DependentsAdjustmentReason = params.DependentsAdjustmentReason;
      this.DependentsCurrentClassLevel = params.DependentsCurrentClassLevel;
      this.DependentsCurrentClassLevelName =
        params.DependentsCurrentClassLevelName;

      this.ApprovedbyDepartment = params.ApprovedbyDepartment;
      this.ApprovedbyDepartmentRemark = params.ApprovedbyDepartmentRemark;
      this.ApprovedbyDepartmentDate = params.ApprovedbyDepartmentDate;
      this.ApprovedbyProjectAuthorized = params.ApprovedbyProjectAuthorized;
      this.ApprovedbyProjectAuthorizedRemark =
        params.ApprovedbyProjectAuthorizedRemark;
      this.ApprovedbyProjectAuthorizedDate =
        params.ApprovedbyProjectAuthorizedDate;
      this.ApprovedbySNEMCHRDepartment = params.ApprovedbySNEMCHRDepartment;
      this.ApprovedbySNEMCHRDepartmentRemark =
        params.ApprovedbySNEMCHRDepartmentRemark;
      this.ApprovedbySNEMCHRDepartmentDate =
        params.ApprovedbySNEMCHRDepartmentDate;
      this.ApprovedbySNEMCAuthorized = params.ApprovedbySNEMCAuthorized;
      this.ApprovedbySNEMCAuthorizedRemark =
        params.ApprovedbySNEMCAuthorizedRemark;
      this.ApprovedbySNEMCAuthorizedDate = params.ApprovedbySNEMCAuthorizedDate;
      this.UploadingDocument1 = params.UploadingDocument1;
      this.UploadingDocument2 = params.UploadingDocument2;
      this.UploadingDocument3 = params.UploadingDocument3;
      this.UploadingDocument4 = params.UploadingDocument4;
      this.UploadingDocument5 = params.UploadingDocument5;
      this.getProjectsChildren(this.ProjectID);
      params.Details.forEach((_, index, Array) => {
        if (params.Details[index]) {
          if (index <= 5) {
            this.CostAllocationDetails[index] = JSON.parse(
              JSON.stringify(params.Details[index])
            );
          } else {
            this.CostAllocationDetails.push(
              JSON.parse(JSON.stringify(params.Details[index]))
            );
          }
        }
      });
      //   this.CostAllocationDetails.forEach((item, index, Array) => {
      //     if (!item.ClassID) {
      //       Array[index].ClassID = this.ClassID;
      //     }
      //   });
    },
    clearData() {
      this.ID = null;
      this.KeyID = null;
      this.ApplicationDate = null;
      this.ApplicationNumber = null;
      this.EmployeeID = null;
      this.EmployeeCode = null;
      this.NameinChinese = null;
      this.NameinEnglish = null;
      this.Gender = null;
      this.Nationality = null;
      this.ProjectID = null;
      this.SubProjectID = null;
      this.CompanyID = null;
      this.DepartmentID = null;
      this.JobProfession = null;
      this.BorderNumber = null;
      this.IQAMAID = null;
      this.SPONSOR = null;
      this.ClassID = null;

      this.ClassName = null;
      this.EmployeeAdjustmentReason = null;
      this.EmployeeNewClassLevel = null;
      this.EmployeeNewClassLevelName = null;
      this.DependentsAdjustmentReason = null;
      this.DependentsCurrentClassLevel = null;
      this.DependentsCurrentClassLevelName = null;

      this.ApprovedbyDepartment = null;
      this.ApprovedbyDepartmentRemark = null;
      this.ApprovedbyDepartmentDate = null;
      this.ApprovedbyProjectAuthorized = null;
      this.ApprovedbyProjectAuthorizedRemark = null;
      this.ApprovedbyProjectAuthorizedDate = null;
      this.ApprovedbySNEMCHRDepartment = null;
      this.ApprovedbySNEMCHRDepartmentRemark = null;
      this.ApprovedbySNEMCHRDepartmentDate = null;
      this.ApprovedbySNEMCAuthorized = null;
      this.ApprovedbySNEMCAuthorizedRemark = null;
      this.ApprovedbySNEMCAuthorizedDate = null;
      this.UploadingDocument1 = null;
      this.UploadingDocument2 = null;
      this.UploadingDocument3 = null;
      this.UploadingDocument4 = null;
      this.UploadingDocument5 = null;
      this.subPojectList = [];
      this.CostAllocationDetails = [];
      this.costAllocationItem = {
        ID: null,
        KeyID: null,
        MainKeyID: null,
        DependentName: null,
        DateofBirth: null,
        BorderNumber: null,
        IQAMAID: null,
        PhoneNOinWorkingCountry: null,
        DependentsRelationship: null,
        ClassID: null,
      };
      for (let i = 0; i < 5; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
    },
    //通过员工编码读取数据
    async changeEmployeeID(index) {
      const value = this.EmployeeCode;
      if (!value) {
        return;
      }
      let userInfo = null;
      await getUsersCode({ code: value, codeType: 2 }).then((res) => {
        if (res.status === 200 && res.response) {
          userInfo = res.response;
        }
      });
      if (userInfo) {
        console.log("userInfo", userInfo);
        this.Gender = userInfo.Gender;

        this.Nationality = userInfo.CountryID;
        this.DateofBirth = userInfo.DateofBirth;

        this.PhoneNOinWorkingCountry = userInfo.PhoneNOinWorkingCountry;
        this.MaritalStatus = userInfo.MaritalStatus;

        this.NameinChinese = userInfo.ChineseName;
        this.ProjectID = userInfo.ProjectID;
        this.SubProjectID = userInfo.SubProjectID;
        this.CompanyID = userInfo.CompanyID;
        this.JobProfession = userInfo.PositionID;

        this.IQAMAID = userInfo.IQAMAID;
        this.SPONSOR = userInfo.Sponsor;
        this.BorderNumber = userInfo.BorderNumber;
        this.IQAMAValidDate = userInfo.IQAMAValidDate;
        this.ClassID = userInfo.MedicalInsuranceClassLevel 
        this.getProjectsChildren(userInfo.ProjectID);

        if (this.ClassID) {
          this.DependentsCurrentClassLevel = this.ClassID;
          this.CostAllocationDetails.forEach((_, index, Array) => {
            Array[index].ClassID = this.ClassID;
          });
          this.costAllocationItem.ClassID = this.ClassID;
        }
      }
    },
    changeAdd(index) {
      if (index === this.CostAllocationDetails.length - 1) {
        this.CostAllocationDetails.push(
          JSON.parse(JSON.stringify(this.costAllocationItem))
        );
      }
    },
    //通过选中项目获取分项目
    changeProjectID() {
      this.subPojectList = [];
      this.SubProjectID = null;
      if (this.ProjectID || this.ProjectID === 0) {
        this.getProjectsChildren(this.ProjectID);
      }
    },

    getProjectsChildren(id) {
      getProjectsChildren({ id }).then((res) => {
        if (res.status === 200) {
          this.subPojectList = res.response;
        }
      });
    },
    deleteItem(index) {
      switch (index) {
        case 1:
          this.UploadingDocument1 = null;
          break;
        case 2:
          this.UploadingDocument2 = null;
          break;
        case 3:
          this.UploadingDocument3 = null;
          break;
        case 4:
          this.UploadingDocument4 = null;
          break;
        case 5:
          this.UploadingDocument5 = null;
          break;
      }
    },
    uploadAttachment1(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument1 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument2 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment3(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument3 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment4(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument4 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment5(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument5 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    download(data) {
      location.href = data;
    },
  },
  created() {
    for (let i = 0; i < 5; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    //国籍
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.nationalityList = res.response.Countries;
        this.Marriages = res.response.Marriages;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getEmployeeMedicalInsuranceClassLevelsAll().then((res) => {
      if (res.status === 200) {
        this.classList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .tbale-img1 {
    max-width: 120px;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .tr-active {
    background-color: #ecf5ff;
  }
  .text-size {
    font-size: 16px;
    color: #000;
    text-align: center;
    max-width: 200px;
    .el-checkbox {
      margin: 10px 0;
    }
    span {
      margin-right: 20px;
    }
    div {
      margin: 10px 0;
    }
    .input-box {
      display: inline-block;
      width: 100px;
      border-bottom: 1px solid #000;
      margin: 10px 0;
    }
  }
  .div-box-content {
    width: 100%;
    display: flex;
    .div-box-content-left {
      width: 40%;
      padding: 10px 0;
      margin-right: 5%;
      flex-shrink: 0;
      line-height: 20px;
      p {
        margin-top: 20px;
      }
    }
    .div-box-content-right {
      width: 50%;
      .content {
        text-align: center;
        font-size: 12px;
        padding: 5px 0;
      }
    }
  }
  .text-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: 50%;
    }
    .sign-box {
      display: flex;
      align-items: center;
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      min-width: 20px;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 10px 5px;
      .el-select {
        width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
      .flex {
        display: flex;
        font-size: 13px;
        color: #000;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;

        .el-checkbox {
          font-size: 18px;
          color: #000;
          margin-right: 10px;
        }
        .input-box {
          width: 100px;
          border-bottom: 1px solid #000;
          margin: 0 10px;
        }
      }
      .flex2 {
        display: flex;
        font-size: 13px;
        color: #000;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
        .span {
          flex-shrink: 0;
          margin-left: 30px;
        }
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 13px;
      padding: 5px;
      p {
        margin: 10px 0;
      }
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: column;
  cursor: pointer;
  .shenpi-tips {
    color: #315efb;
    margin-left: 0;
    margin-top: 3px;
  }
}
.shenpi-color {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: row;
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.card-upload >>> .el-upload-list {
  display: none;
}
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}

.tr-active >>> .el-input__inner {
  background-color: #ecf5ff;
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.el-date-editor {
  width: 100%;
}
.el-checkbox >>> .el-checkbox__inner {
  background-color: #fff !important;
  border-color: #000 !important;
}
.el-checkbox >>> .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border-color: #000;
}
.el-date-editor {
  max-width: 500px;
}
.sign {
  max-width: 200px;
}
.img-box {
  min-height: 50px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0;
  display: flex;
  align-items: center;
}
.img-box > img {
  max-width: 200px;
  margin-left: 0;
}

.el-checkbox
  >>> .el-checkbox__input.is-disabled.is-checked
  .el-checkbox__inner::after {
  border-color: #000 !important;
}
</style>